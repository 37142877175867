<template>
	<div class="main-view">
		<teacher-signup />
	</div>
</template>

<script>
import TeacherSignup from '@/components/teacher-signup/teacher-signup'

export default {
	name: 'view-teacher-signup',
	components: { TeacherSignup }
}
</script>

<style lang="stylus" scoped>

.main-view
	width 100%
	height 100%
	
</style>
