<template>
	<div class="teacher-signup">
		<div class="head">
			<div class="main-title">
				<span v-if="current_page === 0">About you</span>
				<span v-else-if="current_page === 1">About your students</span>
				<span v-else-if="current_page === 2">About Ithrive sim</span>
				<span v-else>Sign up</span>
			</div>
			<div class="bullets">
				<div class="bullet" :class="{ active: current_page >= 0 }"></div>
				<div class="bullet" :class="{ active: current_page >= 1 }"></div>
				<!-- <div class="bullet" :class="{ active: current_page >= 2 }"></div> -->
			</div>
		</div>
		<div class="content">


			<page v-show="current_page === 0" ref="page0" @next="next" @error="display_error">
				<p class="intro">Thank you for your interest in scheduling an iThrive Sim session. Filling out this brief form will give us the information we need to set up your session and also help us better understand how educators are using iThrive Sim with their students.</p>
				
				<field-input type="text" v-model="form.first_name" :validations="['mandatory']" label="First name" placeholder="First name" />
				<field-input type="text" v-model="form.last_name" :validations="['mandatory']" label="Last name" placeholder="Last name" />
				<field-input type="text" v-model="form.email" :validations="['mandatory','email']" label="Educator e-mail address" description="Please use the email associated with your school. This will be the email you use to login." placeholder="exemple@exemple.com" />
				<field-input type="text" v-model="form.email_confirmation" :validations="['mandatory','email','equal']" :equal="form.email" label="Confirm your e-mail address" placeholder="exemple@exemple.com" />
				<field-input type="password" v-model="form.password" :validations="['mandatory']" :equal="form.email" label="Your desired password" placeholder="Your password" />
				<div class="separator"></div>
				<field-input type="text" v-model="form.years_of_teaching" :validations="['mandatory']" label="How many years have you been teaching?" placeholder="Years of teaching" />
				<field-input type="radio" v-model="form.simulations" :validations="['mandatory']" label="Have you used role playing simulations before?" name="simulations" :options="data_signup.options.yes_no" />
				<div class="separator"></div>
				<field-input type="text" v-model="form.school_name" :validations="['mandatory']" label="What is the name of your school where you plan to use iThrive Sim?" placeholder="School name" />
				<field-input type="select" v-model="form.country" :validations="['mandatory']" label="Which country do you currently teach in?" :options="data_signup.options.countries" description="Use the dropdown menu to select your answer" placeholder="Country" />
				<div class="group" :class="{ inactive: !is_usa }">
					<div class="condition">Conditional to the United States of America</div>
					<field-input type="text" v-model="form.us_state" :disabled="!is_usa" label="State" placeholder="State" />
					<field-input type="text" v-model="form.us_city" :disabled="!is_usa" label="City/Town" placeholder="City" />
				</div>
			</page>

			<page v-show="current_page === 1" ref="page1" @next="next" @error="display_error">
				<!-- <field-input type="text" v-model="form.education_establishment" :validations="['mandatory']" label="Education establishment name" placeholder="Education establishment" /> -->
				<field-input type="checkboxes" v-model="form.grades" :validations="['mandatory']" :options="data_signup.options.grades" label="What grade do you teach?" description="Check all that apply" name="grade" />
				<field-input type="checkboxes" v-model="form.subjects" :validations="['mandatory']" :options="data_signup.options.subjects" label="What subject(s) do you teach?" description="Check all that apply" name="subjects" />
				<div class="separator"></div>
				<field-input type="captcha" v-model="captcha" :validations="[ 'mandatory', 'captcha' ]" :captcha-numbers="captcha_numbers" placeholder="Result" />
				<div class="separator"></div>
				<!-- <field-input type="select" v-model="form.course_number" :validations="['mandatory']" label="In how many different courses do you think you’ll use iThrive Sim?" :options="data_signup.options.course_number" description="Select a number" placeholder="Number of courses" /> -->
				<!-- <field-input type="radio" v-model="form.internet_access" :validations="['mandatory', 'equal']" equal="yes" description="Your students will need access to an Internet enabled device, and if played virtually, access to a common video conferencing software. DO YOUR STUDENTS HAVE ACCESS TO THESE?" name="internet_access" :options="data_signup.options.yes_no" /> -->
				<p class="info"><ui-icon name="alert-triangle" :size="32" /> Your students will need access to an internet enabled device to play, and if played remotely online, access to a common video conferencing software.</p>
				
				<!-- <div class="group" :class="{ inactive: form.internet_access !== 'yes' }">
					<div class="condition">Conditional to the Internet access</div>
					<field-input type="checkboxes" v-model="form.preferred_video" :validations="['mandatory']" :disabled="form.internet_access !== 'yes'" :options="data_signup.options.video_softwares" label="What is the preferred video conference system you and your students use for distance learning?" description="Check all that apply" name="video_softwares" />
				</div> -->
			</page>

			<!-- <page v-show="current_page === 2" ref="page2" @next="next" @error="display_error">
				<field-input type="select" v-model="form.known_from" :validations="['mandatory']" label="How did you hear about the ithrive sim?" :options="data_signup.options.how_hear_about_ithrive" description="Use the dropdown menu to select your answer." placeholder="Heard from..." />

				<field-input type="calendly" label="We are currently facilitating all games." description="Please schedule your session below by choosing an available slot." />
				<div class="row">
					<field-input type="select" v-model="form.month" class="three-quarter" :validations="['mandatory']" label="When do you plan on using ithrive sim?" :options="data_signup.options.months" description="Pick a month in 2020 or 2021." placeholder="Month" />
					<field-input type="select" v-model="form.year" class="one-quarter" :validations="['mandatory']" label="" :options="data_signup.options.years" placeholder="Year" />
				</div>
			</page> -->

			<div v-if="current_page > 0" class="main-button prev active" v-hammer:tap="() => prev()">Back</div>
			<div class="main-button next active" :class="{ last: current_page >= 2, error }" v-hammer:tap="() => submit()">
				<span v-if="current_page >= 2">Complete registration request</span>
				<span v-else>Next</span>
			</div>
			<div v-if="error_message" class="main-error-message" v-hammer:tap="() => error_message = ''"><strong>Error!</strong>{{ error_message }}</div>
		</div>

		<div v-if="current_page === 2 && !loading" class="end">
			<div class="end-content">
				<div class="left">
					<div class="title">Thank you for registering!</div>
					<p class="text">Registration application are typically reviewed within 1 to 2 business days from registration.</p>
					<p class="text">Please check your email for confirmation at {{ form.email }}.</p>
				</div>
				<div class="right">
					<div class="logo"></div>
				</div>
			</div>
		</div>

		<div v-show="loading" class="loader">
			<h1>Sending...</h1>
			<ui-icon name="button-loader" :size="64" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import Page from '@/components/teacher-signup/page'
import FieldInput from '@/components/teacher-signup/field-input'
import data_signup from '@/data/teacher-signup.json'

export default {
	name: 'teacher-signup',
	data() {
		return {
			loading: false,
			error: false,
			error_message: '',
			current_page: 0,
			captcha_numbers: [],
			captcha: '',
			form: {
				first_name: '',
				last_name: '',
				email: '',
				email_confirmation: '',
				password: '',
				years_of_teaching: '',
				simulations: '',
				school_name: '',
				country: '',
				us_state: '',
				us_city: '',
				education_establishment: '',
				grades: [ ],
				subjects: [ ],
				internet_access: '',
				comment: '',
				known_from: '',
				course_number: '',
				month: '',
				year: '',
				preferred_video: [ ]
			}
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		is_usa() {
			return this.form.country === 'United States of America (USA)'
		},
		data_signup() {
			return data_signup
		},
	},
	methods: {
		async submit() {
			if (this.$refs['page' + this.current_page]) {
				this.$refs['page' + this.current_page].submit()
			}
			if (this.current_page >= 2 && !this.error) {
				this.loading = true
				let data = {
					username: this.form.email,
					email: this.form.email,
					password: this.form.password,
					firstname: this.form.first_name,
					lastname: this.form.last_name,
					years_of_teaching: this.form.years_of_teaching,
					role_playing: this.form.simulations === 'yes',
					country: this.form.country,
					state: (this.form.us_state) ? this.form.us_state : 'N/A',
					town: (this.form.us_city) ? this.form.us_state : 'N/A',
					establishment_name: this.form.education_establishment,
					school_name: this.form.school_name,
					school_city: 'N/A',
					school_state: 'N/A',
					grades: this.form.grades.join(', '),
					subjects: this.form.subjects.join(', '),
					device_access: this.form.internet_access === 'yes',
					explanation: this.form.comment,
					source: this.form.known_from,
					course_number: parseInt(this.form.course_number ? this.form.course_number : 0),
					month: parseInt(this.form.month ? this.form.month : 0),
					year: parseInt(this.form.year ? this.form.year : 0),
					preferred_video: this.form.preferred_video.join(', ')
				}
				let r = await Apollo.mutate('REGISTER', data)
				if (!r || !r.status) {
					this.display_error()
					this.error_message = r.message
					this.current_page--
				}
				this.loading = false
			}
		},
		display_error() {
			this.error = true
			setTimeout(() => {
				this.error = false
				this.generate_captcha()
			}, 500)
		},
		next() {
			this.current_page++
		},
		prev() {
			this.current_page--
		},
		generate_captcha() {
			this.captcha = ''
			this.captcha_numbers = [ Math.floor(Math.random() * 9) + 1, Math.floor(Math.random() * 9) + 1 ]
		}
	},
	created() {
		this.generate_captcha()
	},
	components: { Page, FieldInput }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.teacher-signup .content .page .page-content
		.row
			display block
			.field-input
				&.three-quarter, &.one-quarter
					width 100%
					margin-right 0
					margin-left 0
					margin-top 0
		
.teacher-signup
	display block
	height 100%
	.head
		padding 40px 24px
		max-width 700px
		width 100%
		height 128px
		margin 0 auto
		&.hidden
			opacity 0
			user-select none
		.main-title
			margin 0 0 16px 0
			font-weight 700
			font-family 'Josefin Sans'
			text-transform uppercase
			font-size 2.4rem
			color var(--color-primary)
			line-height 24px
		.bullets
			flex flex-start flex-start
			height 8px
			padding-left 3px
			.bullet
				width @height
				height @height
				margin 0 12px 0 0
				border-radius 50%
				box-shadow inset 0 0 0 1px var(--color-primary)
				// transition 0.25s easeOutBack
				&.active
					background-color var(--color-primary)
					box-shadow 0 0 0 3px var(--color-primary)
					animation bullet 0.25s easeOutBack
	.content
		width 100%
		height calc(100% - 128px)
		.page
			height 100%
			.page-content

				.intro
					margin 0 0 24px 0
					color var(--color-text)
		
				.separator
					height 2px
					margin 32px 0
					background-color var(--color-primary)
					
				.row
					flex flex-start flex-start
					.field-input
						&:first-child
							margin-right 8px
						&:last-child
							margin-left 8px
							margin-top 42px
						&.three-quarter
							width calc(70% - 8px)
						&.one-quarter
							width calc(30% - 8px)
				
				.error-info
					background-color var(--color-error)
					padding 8px 12px
					border-radius 4px
					margin -8px 0 32px 0
					font-size 1.4rem
					line-height 1.4em
				
				.info
					flex flex-start flex-start
					background-color var(--color-primary)
					padding 12px 16px
					border-radius 4px
					margin -8px 0 32px 0
					font-size 1.4rem
					line-height 1.2em
					color var(--color-bg)
					>>> .ui-icon
						margin 0 8px 0 0
						flex-shrink 0
						color var(--color-bg)
						svg
							path, line
								stroke @color !important
								fill none !important
					
				.group
					&.inactive
						.condition
							opacity 0.25
					.condition
						position absolute
						left -224px
						width 200px
						height 100%
						padding-right 24px
						border-right 2px solid var(--color-primary)
						text-align right
						text-transform uppercase
						color var(--color-button-bg)
		
		.main-button
			position absolute
			width 200px
			height 40px
			border-radius 4px
			background-color var(--color-button-bg)
			line-height 44px
			font-size 2rem
			font-family 'Josefin sans'
			text-transform uppercase
			color var(--color-button-text)
			text-align center
			cursor not-allowed
			opacity 0.5
			&.next
				left calc(50% + 350px + 48px)
				bottom 48px
				&.error
					background-color var(--color-error)
					color var(--color-error-text)
					animation shake 0.25s infinite
					&:hover
						background-color @background-color
					&:active
						background-color @background-color
			&.prev
				left calc(50% - (350px + 48px + 200px))
				top 23px
			&.active
				opacity 1
				cursor pointer
				&:hover
					background-color var(--color-button-bg-hover)
				&:active
					background-color var(--color-button-bg-hover)
					transform translateY(2px)
			&.last
				height auto
				padding 16px 8px 12px 8px
				// background-color #4DBA8D
				// color #fff
				line-height 24px
				font-size 1.8rem
				// &:hover
				// 	background-color darken(#4DBA8D, 10%)
				// &:active
				// 	background-color darken(#4DBA8D, 20%)
				// 	transform translateY(2px)
		.main-error-message
			position absolute
			right 16px
			max-width 320px
			bottom 64px
			padding 16px
			background-color var(--color-error)
			border-radius 8px
			font-size 1.4rem
			line-height 1.4em
			cursor pointer
			&:after
				content 'X'
				position absolute
				right -8px
				top -8px
				width 32px
				height 32px
				border-radius 50%
				background-color var(--color-error)
				font-size 1.6rem
				line-height (@height + 4px)
				font-weight 700
				font-family 'Josefin Sans'
				text-align center
			strong
				display block
				text-transform uppercase
				font-weight 700
	.loader
		absolute 0
		background-color var(--color-bg)
		flex center center
		flex-direction column
		font-family 'Josefin Sans'
		user-select none
		cursor wait

	.end
		absolute 0
		background-color var(--color-bg)
		flex center center
		.end-content
			flex center center
			padding 40px 24px
			width 100%
			height 370px
			.left
				text-align right
				width 500px
				padding 64px 32px 64px 0
				margin 0 32px 0 0
				border-right 2px solid var(--color-primary)
				.title
					margin 0 0 24px 0
					color var(--color-primary)
					font-size 2.8rem
					font-family 'Josefin sans'
					text-transform uppercase
				.text
					margin 0 0 16px 0
					color var(--color-primary)
					// opacity 0.5
					font-size 2rem
					&:last-child
						margin 0
			.right
				width 200px
				height 100%
				flex flex-start flex-end
				.logo
					width round(390px / 2)
					height round(378px / 2)
					background url(../../assets/img/logo.png) center center no-repeat
					background-size contain
					transform translateY(48px)
			
@keyframes bullet
	0%, 100%
		transform none
	50%
		transform scale(1.2)



@media (max-width: 1240px)
	.teacher-signup
		.head
			padding 24px
			height 96px
		.content
			height calc(100% - 156px)
			.main-button.prev
				top auto
				right auto
				bottom -48px
				left 16px
				width 160px
			.main-button.next
				top auto
				left auto
				bottom -48px
				right 16px
				width 160px


@media (max-width: 730px)
	.teacher-signup
		.end
			.end-content
				flex-direction column
				height auto
				.left
					width 100%
					margin 0 0 32px 0
					padding 0 0 32px 0
					border none
					border-bottom 2px solid var(--color-primary)
					text-align center
					.text
						font-size 1.6rem
						line-height 1.4em
				.right
					width 100%
					height auto
					text-align center
					.logo
						margin 0 auto
						transform none
						width round(390px / 3)
						height round(378px / 3)
		

@keyframes shake
	0%
		transform translate(2px, 0) rotate(0deg)
	10%
		transform translate(-1px, 0) rotate(-1deg)
	20%
		transform translate(-3px, 0) rotate(1deg)
	30%
		transform translate(0px, 0) rotate(0deg)
	40%
		transform translate(1px, 0) rotate(1deg)
	50%
		transform translate(-1px, 0) rotate(-1deg)
	60%
		transform translate(-3px, 0) rotate(0deg)
	70%
		transform translate(2px, 0) rotate(-1deg)
	80%
		transform translate(-1px, 0) rotate(1deg)
	90%
		transform translate(2px, 0) rotate(0deg)
	100%
		transform translate(1px, 0) rotate(-1deg)
		

</style>
