<template>
	<div class="page">
		<div class="scroll" v-scrollbar>
			<div>
				<div class="page-content">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'page',
	methods: {
		submit() {
			let error = false
			this.$children.forEach((c) => {
				c.error_visible = true
				if (c.errors) error = true
			})
			if (!error) this.$emit('next')
			else this.$emit('error')
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.page
	height 100%
	.page-content
		width 100%
		max-width 700px
		margin 0 auto
		padding 0 24px 24px 24px


</style>
