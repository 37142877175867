<template>
	<div class="field-input" :class="{ disabled, calendly: type === 'calendly' }">
		<label v-if="label" class="label">{{ label }}</label>
		<div v-if="type === 'captcha'" class="label">
			<canvas ref="canvas" height="23" width="400"></canvas>
		</div>
		<div v-if="description" class="description">{{ transformed_description }}</div>
		
		<input v-if="type === 'text' || type === 'captcha'" type="text" class="input" :disabled="disabled" :placeholder="placeholder" v-model="local_value" />
		
		<div v-if="type === 'password'">
			<input :type="password_type" class="input" :disabled="disabled" :placeholder="placeholder" v-model="local_value" />
			<div class="password-switch" v-hammer:tap="password_switch">
				<svg v-if="password_type === 'password'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
				<svg v-if="password_type === 'input'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
			</div>
		</div>

		<textarea v-if="type === 'textarea'" class="input" rows="10" :disabled="disabled" :placeholder="placeholder" v-model="local_value" />

		<select v-if="type === 'select'" class="input" :class="{ empty: !local_value }" v-model="local_value">
			<option value="" disabled>{{ placeholder }}</option>
			<option v-for="(option, index) in options" :key="index" :value="option.value">{{ option.text }}</option>
		</select>
		
		<div v-if="type === 'radio'" class="radios">
			<label v-for="(option, index) in options" :key="index" class="radio" :for="name + index">
				<div class="radio-bullet">
					<input type="radio" :name="name" :value="option.value" v-model="local_value" :id="name + index">
					<div v-if="local_value === option.value" class="checked"></div>
				</div>
				<div class="radio-label">{{ option.text }}</div>
			</label>
		</div>
		
		<div v-if="type === 'checkboxes'" class="checkboxes">
			<label v-for="(option, index) in options" :key="index" class="checkbox" :for="name + index">
				<div class="checkbox-bullet">
					<input type="checkbox" :name="name" :value="option.value" v-model="local_value" :id="name + index">
					<div v-if="local_value.includes(option.value)" class="checked"></div>
				</div>
				<div class="checkbox-label">{{ option.text }}</div>
			</label>
		</div>

		<!-- <div v-if="type === 'calendly'" class="calendly-wrap" ref="calendly"></div> -->

		<div v-if="errors && error_visible && errors !== 'HIDDEN'" class="error">{{ errors }}</div>

	</div>
</template>

<script>
import data_signup from '@/data/teacher-signup.json'

export default {
	name: 'field-input',
	data() {
		return {
			ready: false,
			local_value: null,
			error_visible: false,
			password_type: 'password'
		}
	},
	props: {
		value: { default: null },
		type: { default: 'text' },
		name: { default: '' },
		label: { default: '' },
		placeholder: { default: '' },
		description: { default: '' },
		equal: { default: '' },
		options: { default: Array },
		disabled: { default: false },
		characters: { default: false },
		validations: { default: Array },
		captchaNumbers: { default: Array },
	},
	computed: {
		transformed_description() {
			if (this.characters) {
				return this.description.replace('[[CHARACTERS_LEFT]]', this.characters - this.value.length)
			}
			return this.description
		},
		errors() {
			if (this.validations) {
				if (this.validations.includes('mandatory') && !this.local_value.length) {
					return data_signup.errors.mandatory
				}
				if (this.validations.includes('email') && this.local_value) {
					var re = /\S+@\S+\.\S+/
					if (!re.test(this.local_value)) return data_signup.errors.email
				}
				if (this.validations.includes('equal') && this.local_value) {
					if (this.local_value != this.equal) return data_signup.errors.equal.replace('[[VALUE]]', this.equal)
				}
				if (this.validations.includes('captcha') && this.local_value && this.captchaNumbers[0] + this.captchaNumbers[1] !== parseInt(this.local_value)) {
					return data_signup.errors.captcha
				}
			}
			return null
		}
	},
	watch: {
		captchaNumbers() {
			this.generate_captcha()
		},
		value() {
			this.local_value = this.value
		},
		local_value(newValue) {
			this.$emit('input', newValue)
			if (this.ready) this.error_visible = true
		}
	},
	methods: {
		password_switch() {
			this.password_type = this.password_type === 'password' ? 'input' : 'password'
		},
		generate_captcha() {
			if (this.type === 'captcha') {
				const canvas = this.$refs.canvas
				const ctx = canvas.getContext('2d')
				ctx.clearRect(0, 0, canvas.width, canvas.height)
				ctx.font = "18px 'Poppins', Arial"
				ctx.fillStyle = "#a9e0f6"
				ctx.fillText(this.captchaNumbers[0] + ' + ' + this.captchaNumbers[1] + ' =', 0, 20)
			}
		}
	},
	created() {
		this.local_value = this.value
		this.$nextTick(() => {
			this.ready = true
		})
	},
	mounted() {
		this.generate_captcha()
		// eslint-disable-next-line no-undef
		// Calendly.initInlineWidget({
		// 	url: 'https://calendly.com/c/EFCUOLG4KC6GDCR7',
		// 	parentElement: this.$refs.calendly,
		// 	prefill: {},
		// 	utm: {}
		// })
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.field-input
	margin 0 0 24px 0
	&.disabled
		opacity 0.25
		&:after
			content ''
			absolute 0
	// &.calendly
	// 	width 100%
	// 	height 850px
	// 	overflow hidden
	// 	.calendly-wrap
	// 		height 100%
	// 		width 100%
	// 		// background url(../../assets/img/button-loader.svg) top 64px center no-repeat
	// 		// background-size 64px
	// 		&:before
	// 			content ''
	// 			absolute 0
	// 			top 8px
	// 			bottom 48px
	// 			background-color alpha(#fff, 10%)
	// 			border-radius 4px
	// 		>>> iframe
	// 			width 100%
	// 			transform translateY(-48px)
	.label
		display block
		padding-left 16px
		color var(--color-primary)
		text-transform uppercase
		user-select none
	.description
		display block
		padding-left 16px
		color var(--color-input-text-text)
		opacity 0.5
		user-select none
	.input
		display block
		margin 2px 0 0 0
		width 100%
		height 40px
		padding 0 16px
		border-radius 4px
		background var(--color-input-text-bg)
		color var(--color-input-text-text)
		border none
		outline none
		appearance none
		font inherit
		&[disabled]
			cursor not-allowed
			&:focus
				background-color var(--color-input-text-bg)
		&:focus
			background-color var(--color-input-text-bg-focus)
	.password-switch
		position absolute
		right 0
		top 0
		height 40px
		width 48px
		cursor pointer
		color var(--color-input-text-text)
		flex center center
		opacity 0.25
		&:hover
			opacity 0.5
		>>> svg
			height 50%
			width 50%
	textarea.input
		height auto
		padding 16px
		resize none
	select.input
		background-image url(../../assets/img/chevron-down.svg)
		background-position right 12px center
		background-size 24px
		background-repeat no-repeat
		&.empty
			color var(--color-input-text-placeholder)
	.radio
		flex flex-start center
		margin 8px 0 0 0
		cursor pointer
		&:hover
			.radio-bullet
				background-color alpha(#fff, 10%)
		.radio-bullet
			width 24px
			height 24px
			margin 0 8px 0 0
			border 2px solid var(--color-primary)
			border-radius 50%
			input
				absolute 0
				opacity 0
				cursor pointer
			.checked
				absolute 3px
				border-radius 50%
				background-color var(--color-primary)
		.radio-label
			flex flex-start flex-start
			color var(--color-primary)
			user-select none
	.checkboxes
		flex flex-start flex-start
		flex-wrap wrap
		.checkbox
			flex flex-start center
			width 25%
			margin 8px 0
			cursor pointer
			&:hover
				.checkbox-bullet
					background-color alpha(#fff, 10%)
			.checkbox-bullet
				width 24px
				height 24px
				margin 0 8px 0 0
				border 2px solid var(--color-primary)
				input
					absolute 0
					opacity 0
					cursor pointer
				.checked
					absolute 3px
					background-color var(--color-primary)
			.checkbox-label
				flex flex-start flex-start
				color var(--color-primary)
				user-select none
				padding-right 8px
				line-height 14px
	.error
		margin 4px 0 0 0
		color var(--color-error)
		text-transform uppercase
		font-size 1.2rem
		font-weight 700

::placeholder
	color var(--color-input-text-placeholder)
	user-select none
	opacity 1



@media (max-width: 700px)
	.field-input
		&.calendly
			.calendly-wrap
				>>> iframe
					border-radius 8px
					transform translateY(4px)

@media (max-width: 600px)
	.field-input
		.checkboxes
			.checkbox
				width 33.3333%

@media (max-width: 400px)
	.field-input
		.checkboxes
			.checkbox
				width 50%



</style>
